import React from 'react';

import css from './LocationAutocompleteInput.module.css';

const IconHourGlass = () => (
  <svg xmlns="http://www.w3.org/2000/svg"
       height="24px" viewBox="0 -960 960 960" width="24px"
       className={css.iconSvg}
       fill="#e8eaed">
    <path
      className={css.iconSvgGroup}
      d="M480-186q122-112 181-203.5T720-552q0-109-69.5-178.5T480-800q-101 0-170.5 69.5T240-552q0 71 59 162.5T480-186Zm0 79q-14 0-28-5t-25-15q-65-60-115-117t-83.5-110.5q-33.5-53.5-51-103T160-552q0-150 96.5-239T480-880q127 0 223.5 89T800-552q0 45-17.5 94.5t-51 103Q698-301 648-244T533-127q-11 10-25 15t-28 5Zm0-453Zm0 80q33 0 56.5-23.5T560-560q0-33-23.5-56.5T480-640q-33 0-56.5 23.5T400-560q0 33 23.5 56.5T480-480Z" />
  </svg>
);

export default IconHourGlass;
